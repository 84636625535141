<template>
  <div class="report">
    <div class="title">
      AI智能填报是报考高手依据您的成绩通过相关数据计算，为您智能推荐出一套志愿填报方案。请您一定要结合自己的实际情况和填报意愿参考使用。（本模拟志愿方案可供参考，最终制定方案需登陆省考试院官网填写）
    </div>

    <el-row>
      <el-col :span="19">
        <conditional-search @doSearch="doSearch"></conditional-search>
      </el-col>

      <el-col :span="4" :offset="1">
        <div v-if="loading" class="refresh"> <i class="el-icon-loading"></i> 刷新志愿表</div>
        <div v-else class="refresh" @click="getReport"> <i class="el-icon-refresh-right"></i> 刷新志愿表</div>
        <div class="refresh" @click="add()"> <i class="el-icon-folder-add"></i> 保存到志愿表 </div>
      </el-col>

    </el-row>
    <div v-loading="loading" v-if="data">
      <div v-for="(item, i) in tableData" :key="i" class="tables">
        <table border="1" class="report_table">
          <tr>
            <td class="tip">平行志愿{{ word[i] }}</td>
            <td colspan="5" class="name">
              <span>[{{ item.school_id }}]{{ item.name }}</span>
              <img src="@/assets/img/volunteer/address.png" alt="" />
              {{ item.city_name }}
              <img src="@/assets/img/volunteer/agree.png" alt="" />
              同意调剂
            </td>
          </tr>
          <tr class="title_r">
            <td colspan="2" width="100px">专业序号</td>
            <td width="540px">专业名称</td>
            <td width="190px">招生计划</td>
            <td width="190px">专业详情</td>
            <td width="290px">收费标准</td>
          </tr>
          <tr v-for="(sp, i) in item.specialty" :key="i">
            <td colspan="2" width="100px">专业{{ i + 1 }}</td>
            <td width="540px">[{{ sp.specialty_id | interpo }}]{{ sp.specialty_name }}</td>
            <td width="190px">{{ sp.plan_num }}</td>
            <td width="290px">
              <el-popover placement="top-start" width="200" trigger="hover" :content="sp.remarks">
                <div slot="reference" class="remarks">{{ sp.remarks }}</div>
              </el-popover>
              <!-- {{ sp.remarks }} -->
            </td>
            <td width="120px">{{ sp.tuition }}</td>
          </tr>
        </table>
      </div>

    </div>
    <ToolTip />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToolTip from "./components/ToolTip.vue";
import conditionalSearch from "./components/conditional-search-onekey.vue";

import city from "@/assets/data/conditional-search.js";
//time: 2020/10/30
export default {
  name: "Report",
  computed: { ...mapState(["userInfo", 'scoreLine', 'batch']) },
  components: {
    ToolTip,
    conditionalSearch
  },


  data() {
    return {
      loading: true,
      data: null,
      form: {
        is_wenli: 2,
        score: 580,
      },
      city: city.city,
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      cha: 30,
      tableData: [],
      check: {
        city_id: []
      }
    };
  },
  mounted() {
    this.$emit("Nav", 0);

    this.form.is_wenli = this.userInfo.is_wenli;
    this.form.score = this.userInfo.score;
    this.getReport()
    // this.getLikeCity()

  },

  methods: {
    getLikeCity() {
      console.log(this.userInfo.like_city)
      if (this.userInfo.like_city) {
        if (this.userInfo.like_city.length > 0) {
          console.log(this.city)
          console.log(this.userInfo.like_city)
          console.log(123)
        } else {
          console.log(456)
        }
      } else {
        console.log(456)
      }

      // console.log(this.userInfo.like_city.length)
      // if (this.userInfo.like_city && this.userInfo.like_city != []) {
      //   console.log(123)
      // } else {
      //   console.log(456)
      // }
      // // this.getReport();
    },

    getReport() {
      console.log(this.userInfo.score, this.scoreLine[this.batch - 1])
      this.cha = this.userInfo.score - this.scoreLine[this.batch - 1];

      this.loading = true;
      this.$fecth
        .post("report/report", {
          score: this.form.score,
          is_wenli: this.form.is_wenli,
          batch: this.batch,
          cha: this.cha,
          city_id: this.check.city_id
        })
        .then((res) => {
          this.loading = false;
          this.data = res;
          this.tableData = JSON.parse(res.table);
        });
    },
    add() {
      this.$fecth
        .post("report/oneKeyAdd", {
          data: this.data,
        })
        .then(() => {
          this.$alert("保存成功", "提示", {
            confirmButtonText: "确定",
          });
        });
    },
    doSearch(e) {
      this.check = e
      this.getReport()

    }
  },
};
</script>

<style lang="less" scoped>
.report {
  .title {
    padding: 1px;
    line-height: 30px;
    letter-spacing: 1px;
    text-indent: 2rem;
    color: #5d5e5f;
  }

  .refresh {
    width: 120px;
    background: #409eff;
    color: white;
    display: inline-block;
    border-radius: 30px;
    margin-top: 10px;
    padding: 8px 30px;
    margin-bottom: 20px;

    img {
      position: relative;
      top: 2px;
    }
  }

  .report_table {
    border-collapse: collapse;
    width: 1200px;
    margin-bottom: 40px;
    line-height: 35px;
    text-align: center;

    .name {
      position: relative;

      span {
        position: absolute;
        left: 88px;
      }

      text-align: right;
      text-indent: 2rem;
      padding-right: 80px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 30px;
        position: relative;
        top: 1px;
      }
    }
  }

  .tables {
    border: #e1e7eb;

    .tip {
      width: 150px;
      background: #1f98dc;
      color: white;
    }

    .title_r {
      background: #e1e7eb;
    }
  }

  .add {
    border: #409eff;
    background: #409eff !important;
  }
}
</style>

<style lang="less">
.el-icon-my-export {
  background: url("../../assets/img/volunteer/add_.png") center no-repeat;
  background-size: cover;
}

.el-icon-my-export:before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

//如果直接使用字体图片
//直接在before属性设置对应的content就行
.el-icon-my-export {
  font-size: 16px;
}

.el-icon-my-export:before {
  content: "\e611";
}

.remarks {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>